/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { NavigationItemProps } from '../../../interfaces';
import { useInPageNavContext } from '../../context';
import { StyledLink, StyledListItem } from './NavigationStyles';

export const OverViewItem = ({
  hasSubMenu,
  path,
  title,
  index,
  handleSelectedItem,
  hasParentElement,
  subLevelChildLabel,
  isLastElement,
  isSecondaryLevel,
  isMobile,
}: NavigationItemProps) => {
  const { topLevelActiveLink, sameLevelActiveLink } = useInPageNavContext();
  let notActiveClass: boolean = false;
  const isActive = index === sameLevelActiveLink?.index;

  return (
    <StyledListItem
      role="none"
      className="sibling-item sub-level-parent"
      $hasParentElement={hasParentElement}
      $isSecondaryLevel={isSecondaryLevel}
      $isMobile={isMobile}
    >
      <UeContainer propertyPath="sameLevelInpageNavigation" label="Navigation item">
        <UeReference
          propertyPath={
            isSecondaryLevel
              ? `sameLevelInpageNavigation.${topLevelActiveLink?.index}.subLinks.${index}`
              : `sameLevelInpageNavigation.${index}`
          }
          label="Overview Link field"
        >
          <StyledLink
            className={`sibling-link ${isSecondaryLevel ? `secondary-level-link${isActive ? ' active-link' : ''}` : ''}`}
            href={path}
            role="menuitem"
            aria-expanded
            onClick={(e) => {
              e.stopPropagation();
              e?.preventDefault(); // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const element: HTMLElement | null = e?.target?.parentElement;
              const classActive = element?.classList;
              const hasSubMenuClass = classActive?.contains('has-sub-menu');
              notActiveClass = !classActive?.contains('active-link');

              if (handleSelectedItem && hasSubMenuClass) {
                handleSelectedItem({
                  id: `${title?.replace(/ /g, '-')}-link`,
                  index,
                  text: title,
                  subLevelChildLabel,
                  href: path,
                });
              }

              if (!hasSubMenu && path && notActiveClass && typeof window !== 'undefined') {
                window.location.href = path;
              }
            }}
            data-index={index}
            $hasParentElement={hasParentElement}
            $isSecondaryLevel={isSecondaryLevel}
            $isLastElement={isLastElement}
            data-testid={`data-testid-${title}`}
          >
            <UeElement type="text" property="Text" label="Navigation item">
              {title}
            </UeElement>
          </StyledLink>
        </UeReference>
      </UeContainer>
    </StyledListItem>
  );
};
