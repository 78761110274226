/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useCallback,
  useEffect,
  ReactElement,
  JSXElementConstructor,
  useContext,
} from 'react';
import { Text, Icon } from '@audi/audi-ui-react';
import { UeContainer } from '@oneaudi/falcon-tools';
import NavItems from './NavItems';
import NavBarWrapper from './NavBarWrapper';
import {
  StyledArrowIcon,
  StyledInput,
  StyledOverarchingContent,
  StyledOverarchingName,
  StyledSubMenu,
} from './NavigationStyles';
import { TrackingServiceContext, useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import usePriorityNav from '../../hooks/use-priority-nav';
import { getTopLevelActiveItemHref, hasSubLinks, isParentElement } from '../../utils/utils';
import OverViewLabel from './OverViewLabel';
import { ActiveLinks } from '../../../types';
import PriorityDropDownItem from './PriorityDropDownItem';
import { useMount } from '../../hooks/use-mount';
import { useTracking } from '../../hooks/use-tracking';

type OverarchingContentProps = {
  overarchingName?: string;
};

const OverarchingContent = (props: OverarchingContentProps) => {
  const { overarchingName } = props;
  const {
    parentPageInpageNavigation,
    sameLevelActiveLink,
    topLevelActiveLink,
    secondLevelActiveLink,
    isLoadedFromUrl,
  } = useInPageNavContext();
  const [pageLabel, setPageLabel] = useState<string>('');
  useEffect(() => {
    if (isLoadedFromUrl) {
      if (sameLevelActiveLink) {
        setPageLabel(sameLevelActiveLink?.text || '');
      }
      if (topLevelActiveLink && secondLevelActiveLink) {
        setPageLabel(`${topLevelActiveLink?.text || ''} > ${secondLevelActiveLink?.text || ''}`);
      }
    } else {
      setPageLabel(parentPageInpageNavigation?.fields?.subLevelParentLabel || '');
    }
  }, [isLoadedFromUrl, topLevelActiveLink]);

  return (
    <>
      <StyledInput type="checkbox" name="accordionOverview" id="chckOverview" />
      <StyledOverarchingName htmlFor="chckOverview">
        <StyledOverarchingContent>
          <Text as="p" className="title">
            {overarchingName}
          </Text>
          {pageLabel !== '' ? <Text as="span">{pageLabel}</Text> : <span>&nbsp;</span>}
        </StyledOverarchingContent>
        <StyledArrowIcon>
          <Icon name="arrow-up" size="small" variant="primary" />
        </StyledArrowIcon>
      </StyledOverarchingName>
    </>
  );
};

const TopLevelNav = () => {
  const dispatch = useInPageDispatch();
  const [activePriortyDropDown, setActivePriortyDropDown] = useState(false);
  const [refNavUpdated, setRefNavUpdated] = useState<React.RefObject<HTMLElement>>();
  const [refListUpdated, setRefListUpdated] = useState<React.RefObject<HTMLElement>>();

  const handlePriorityDropDownClick = useCallback(() => {
    setActivePriortyDropDown((prev) => !prev);
  }, []);

  const {
    topLevelActiveLink,
    sameLevelInpageNavigation,
    accordionLabel,
    parentPageInpageNavigation,
    prioprityNavActive,
    priorityNavLoaded,
  } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);

  useMount(() => {
    const value =
      sameLevelInpageNavigation && sameLevelInpageNavigation?.length > 0
        ? sameLevelInpageNavigation[0].title
        : '';
    tracking?.impression({
      elementName: '',
      label: '',
      clickID: '',
      targetURL: '',
      value,
      pos: '',
    });
  });

  useEffect(() => {
    if (dispatch) {
      const interaction = Number(prioprityNavActive && prioprityNavActive?.interaction + 1);
      dispatch({
        type: INPAGENAV_ACTIONS.PRIORITY_NAV_ACTIVE,
        payload: {
          prioprityNavActive: {
            ...prioprityNavActive,
            interaction,
            isDesktop: window.innerWidth >= 1024,
            active: activePriortyDropDown,
          },
        },
      });
    }
  }, [activePriortyDropDown]);

  const { popOverNav, activeLink, priorityItemslength } = usePriorityNav({
    refNavUpdated,
    refListUpdated,
    activePriortyDropDown,
  }) as {
    popOverNav: ReactElement<unknown, string | JSXElementConstructor<unknown>> | null;
    activeLink: number;
    priorityItemslength: number;
  };

  const handleSelectedItem = ({ id, index, text, subLevelChildLabel, target }: ActiveLinks) => {
    if (dispatch) {
      const href = getTopLevelActiveItemHref(sameLevelInpageNavigation, index);
      dispatch({
        type: INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK,
        payload: {
          topLevelActiveLink: {
            id,
            index,
            text,
            subLevelChildLabel: subLevelChildLabel || topLevelActiveLink?.subLevelChildLabel,
            href,
            target,
          },
          subNavActive: {
            index,
            subLinks: {
              hasLinks: hasSubLinks(index, sameLevelInpageNavigation).length > 0,
              active: index !== topLevelActiveLink?.index,
              text: subLevelChildLabel || text,
            },
          },
        },
      });
    }
  };

  const handleListRef = (ref?: React.RefObject<HTMLElement>) => {
    const className = ref?.current?.className || '';
    if (className.indexOf('inpage-nav-menu') > -1) {
      setRefNavUpdated(ref);
    }
    if (className.indexOf('top-ul-list') > -1) {
      setRefListUpdated(ref);
    }
  };

  const priorityNavItemsLength =
    priorityItemslength !== sameLevelInpageNavigation?.length ? priorityItemslength : 0;

  return (
    <UeContainer propertyPath="sameLevelInpageNavigation" label="Navigation bar">
      {/* For mobile heading view */}
      <OverarchingContent overarchingName={accordionLabel} />

      <StyledSubMenu id="menu">
        <NavBarWrapper visible handleListRef={handleListRef}>
          {isParentElement(parentPageInpageNavigation) && <OverViewLabel />}
          {sameLevelInpageNavigation?.map((item, i) => {
            const { fields } = item;
            return (
              <NavItems
                // eslint-disable-next-line react/no-array-index-key
                key={`nav-item-${i}`}
                fields={fields}
                index={i}
                hasParentElement={!!parentPageInpageNavigation}
                handleSelectedItem={handleSelectedItem}
                fieldsLength={sameLevelInpageNavigation.length}
                subNavigation={fields?.subLinks}
              />
            );
          })}
          <PriorityDropDownItem
            handlePriorityDropDownClick={handlePriorityDropDownClick}
            activePriortyDropDown={activePriortyDropDown}
            priorityNavLoaded={activeLink >= 0 && priorityNavLoaded}
            isMobile={!prioprityNavActive?.isDesktop}
            priorityItemslength={prioprityNavActive?.isDesktop ? priorityNavItemsLength : 0}
          />
        </NavBarWrapper>

        {popOverNav}
      </StyledSubMenu>
    </UeContainer>
  );
};

export default TopLevelNav;
