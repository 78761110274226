import {
  createVinByRegExpRemover,
  type UrlSanitizerV2,
} from "@oneaudi/audi-tracking-service";

import {
  type DigitalData,
  type DigitalDataEvent,
  type DigitalDataPage,
  type DigitalDataUser,
  RENDERER_PARTIAL_DIGITAL_DATA_ID,
} from "@oneaudi/falcon-renderer-core/tracking";

const urlSanitizer: UrlSanitizerV2 = createVinByRegExpRemover();

export const AUTH_ON_PAGE_LOAD_EVENT: string = "AUTH-ON-PAGE-LOAD-EVENT";
// Timeout defined in Milliseconds to limit the await for AUTH-ON-PAGE-LOAD-EVENT
export const USER_DATA_FETCH_TIMEOUT: number =
  4 /* seconds */ * 1000; /* milliseconds */

declare global {
  interface Window {
    digitalData?: DigitalData;
  }
}

export function mergeDigitalDataPage(
  data1: DigitalDataPage,
  data2: DigitalDataPage
): DigitalDataPage {
  return {
    attributes: { ...data1.attributes, ...data2.attributes },
    category: { ...data1.category, ...data2.category },
    pageInfo: { ...data1.pageInfo, ...data2.pageInfo },
  };
}

async function getAuthUserData(): Promise<DigitalDataUser> {
  const onAuthUserDataEvent = async (): Promise<DigitalDataUser> =>
    new Promise((resolve) => {
      const handleUserDataEvent = (event: Event) => {
        const { detail } = event as CustomEvent;
        resolve({
          segment: {
            isLoggedIn: detail.isAuthenticated,
            userID: detail.userId,
          },
        });
        document.removeEventListener(
          AUTH_ON_PAGE_LOAD_EVENT,
          handleUserDataEvent
        );
      };
      document.addEventListener(AUTH_ON_PAGE_LOAD_EVENT, handleUserDataEvent);
    });

  const fallbackPromise = new Promise<DigitalDataUser>((resolve) => {
    setTimeout(
      () =>
        resolve({
          segment: { isLoggedIn: "timeout", userID: "" },
        }),
      USER_DATA_FETCH_TIMEOUT
    );
  });

  return Promise.race([onAuthUserDataEvent(), fallbackPromise]);
}

function createDigitalDataAttributeFromHtml<T>(
  htmlTagId: string
): T | undefined {
  const digitalDataAttribute = document.getElementById(htmlTagId);

  if (digitalDataAttribute && digitalDataAttribute.textContent) {
    try {
      return JSON.parse(decodeURIComponent(digitalDataAttribute.textContent));
    } catch (err) {
      console.error(
        `An error occurred while parsing the tracking data provided by the renderer found in the HTML tag with id ${htmlTagId}.`,
        err
      );
    }
  }

  return undefined;
}

export async function initializeTracking(): Promise<void> {
  // Initialize digitalData
  window.digitalData ||= {};
  window.digitalData.page ||= {};
  window.digitalData.event ||= [];
  window.digitalData.user ||= [];

  const {
    page: serverPage,
    component,
    dealer,
    products,
  } = createDigitalDataAttributeFromHtml<DigitalData>(
    RENDERER_PARTIAL_DIGITAL_DATA_ID
  ) ?? {};

  if (serverPage) {
    window.digitalData.page = mergeDigitalDataPage(
      window.digitalData.page,
      serverPage
    );
  }

  // Collect all frontend data for digitalData.page:
  const digitalDataPageFrontend: DigitalDataPage = {
    pageInfo: {
      destinationURL: urlSanitizer(window.location.href || ""),
      referringURL: urlSanitizer(document.referrer || ""),
    },
  };

  // Merge the frontend page data
  window.digitalData.page = mergeDigitalDataPage(
    window.digitalData.page,
    digitalDataPageFrontend
  );

  // Read the digitalData.component data from the renderer
  // Data is enriched by the components (the feature apps) itself.
  if (component) {
    window.digitalData.component = component;
  }

  if (dealer) {
    window.digitalData.dealer = dealer;
  }

  const userData = await getAuthUserData();
  window.digitalData.user.push(userData);

  if (products) {
    window.digitalData.products = {
      ...window.digitalData.products,
      ...products,
    };
  }

  const hasPrimaryConsumerOnPage =
    document.querySelector('feature-app[primary-consumer="true"]') !== null;

  // Send the page_load event, as long as no primaryConsumer is present on the current page
  if (!hasPrimaryConsumerOnPage) {
    performPageTracking();
  }
}

function performPageTracking() {
  const dataLayerEvent: DigitalDataEvent = {
    eventInfo: {
      eventAction: "page_load",
      eventName: "generic",
    },
    attributes: {
      componentName: "",
      label: "",
      currentURL: window.location.href,
      targetURL: "",
      clickID: "",
      elementName: "",
      value: "",
      pos: "",
    },
  };

  window.digitalData?.event?.push(dataLayerEvent);
}
