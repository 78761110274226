/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import '../../styles/app.css';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { useI18n } from '@oneaudi/i18n-context';
import { PAGE_MARGIN_L, PAGE_MARGIN_XL } from '@audi/audi-ui-design-tokens';
import TopLevelNav from '../navigation/TopLevelNav';
import SecondLevelNavDesktop from '../navigation/SecondLevelNavDesktop';
import useToggle from '../../hooks/use-toggle';
import useActiveLink from '../../hooks/use-active-link';
import { getIndexPosition, useTracking } from '../../hooks/use-tracking';
import { TrackingServiceContext, useInPageNavContext } from '../../context';
import { messages } from '../../i18n/messages';
import { useMount } from '../../hooks/use-mount';
import useSticky from '../../hooks/use-sticky';
import { getTrackingLabelDisplay } from '../../utils/utils';

interface IProps {
  trackingService?: TrackingServiceV2;
}

const Container = styled.div<{ $removeStickiness: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(${(props) => props.theme.colors.background.level['0']});
  opacity: 1;
  transition: opacity 0.3s ${(props) => props.theme.easing};
  will-change: opacity;
  padding: 0;
  width: 100%;
  position: ${(props) => (props.$removeStickiness ? '' : 'sticky')};
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &.sticky {
    z-index: 100;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: auto;
  }

  div[data-aue-type='container'] {
    width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0 ${PAGE_MARGIN_L}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding: 0 ${PAGE_MARGIN_XL}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    padding: 0 calc((100% - 1728px) / 2);
  }
`;

const InpageNav: React.FC<IProps> = () => {
  const [visible, toggle] = useToggle(false);
  const {
    topLevelActiveLink,
    secondLevelActiveLink,
    sameLevelActiveLink,
    subNavActive,
    sameLevelInpageNavigation,
    prioprityNavActive,
    priorityNavLoaded,
    removeStickiness,
  } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);
  const { containerRef, isSticky } = useSticky();
  const noContentMessage = useI18n(messages.no_navigation);
  const [isSublinksActiveUrl, setIsSublinksActiveUrl] = useState(false);

  useMount(() => {
    tracking?.appReady();
  });
  const hasSubNavlinks = subNavActive?.subLinks?.hasLinks;
  const isSublinksActive = subNavActive?.subLinks?.active;

  useEffect(() => {
    const canToggle =
      topLevelActiveLink?.index === subNavActive?.index && subNavActive?.subLinks?.hasLinks;

    if (canToggle) {
      toggle();
      // keep subLinks nav open when switching between subnav menus
      if (isSublinksActive) toggle(true);

      // tracking for toggle submenu
      if (topLevelActiveLink && topLevelActiveLink.index !== -1) {
        tracking?.toggleNavigation({
          elementName: 'text link',
          label: topLevelActiveLink.text,
          clickID: '',
          targetURL: topLevelActiveLink.href || '',
          value: 'same level',
          pos: `${getIndexPosition(topLevelActiveLink.index)}`,
          eventName: `inpage navigation - ${isSublinksActive ? 'open' : 'close'} sub navigation`,
        });
      }
    }

    // tracking for topnav link clicks
    if (
      (sameLevelActiveLink && sameLevelActiveLink?.index !== -1 && !hasSubNavlinks) ||
      (topLevelActiveLink && topLevelActiveLink?.index !== -1 && !hasSubNavlinks)
    ) {
      tracking?.navigationItemClick({
        elementName: 'text link',
        label: sameLevelActiveLink?.text || topLevelActiveLink?.text || '',
        clickID: '',
        targetURL: sameLevelActiveLink?.href || topLevelActiveLink?.href || '',
        value: 'same level',
        pos: `${getIndexPosition(sameLevelActiveLink?.index || topLevelActiveLink?.index || -1)}`,
        isSubNavigation: isSublinksActive,
        eventName: `inpage navigation - click on link`,
      });
    }

    // tracking for subnav link clicks
    if (
      (sameLevelActiveLink && secondLevelActiveLink && secondLevelActiveLink.index !== -1) ||
      (topLevelActiveLink && secondLevelActiveLink && secondLevelActiveLink.index !== -1)
    ) {
      // used for creating breadcrumb label
      const secondLeveltext = getTrackingLabelDisplay(
        secondLevelActiveLink,
        sameLevelActiveLink,
        topLevelActiveLink,
      );
      const secondLevelIndex =
        secondLeveltext !== '' ? `-${getIndexPosition(secondLevelActiveLink?.index || -1)}` : '';

      tracking?.navigationItemClick({
        elementName: 'text link',
        label: `${sameLevelActiveLink?.text || topLevelActiveLink?.text}${secondLeveltext}`,
        clickID: '',
        targetURL: secondLevelActiveLink?.href || '',
        value: `${getIndexPosition(secondLevelActiveLink?.index || -1)} sub level`,
        pos: `${getIndexPosition(sameLevelActiveLink?.index || topLevelActiveLink?.index || -1)}${secondLevelIndex}`,
        isSubNavigation: isSublinksActive,
        eventName: `inpage navigation - click on link`,
      });
    }
  }, [sameLevelActiveLink, topLevelActiveLink, secondLevelActiveLink]);

  // tracking for overflow/priority menu
  useEffect(() => {
    if (
      prioprityNavActive?.isDesktop &&
      Number(prioprityNavActive?.interaction) > 1 &&
      priorityNavLoaded
    ) {
      tracking?.toggleNavigation({
        elementName: '',
        label: 'Overflow menu',
        clickID: '',
        targetURL: '',
        value: 'same level',
        pos: '',
        eventName: `inpage navigation - ${prioprityNavActive?.active ? 'open' : 'close'} overflow items`,
      });
    }
  }, [priorityNavLoaded, prioprityNavActive]);

  useEffect(() => {
    if (isSublinksActiveUrl) {
      toggle(true);
    }
  }, [isSublinksActiveUrl]);

  // used to render the active links based on url
  useActiveLink(setIsSublinksActiveUrl);

  return (
    <Container
      data-testid="inpage-navigation"
      id="inpagenav"
      ref={containerRef}
      className={`${isSticky ? 'sticky' : ''}`}
      $removeStickiness={removeStickiness ?? false}
    >
      {sameLevelInpageNavigation && sameLevelInpageNavigation?.length > 0 ? (
        <>
          <TopLevelNav />
          {/* large screens sub level navigation */}
          <SecondLevelNavDesktop visible={visible} />
        </>
      ) : (
        <Text as="p">{noContentMessage}</Text>
      )}
    </Container>
  );
};

export default InpageNav;
