/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';
import {
  PAGE_MARGIN_XS,
  PAGE_MARGIN_S,
  PAGE_MARGIN_M,
  PAGE_MARGIN_XL,
} from '@audi/audi-ui-design-tokens';

export const StyledSubMenu = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: 250ms grid-template-rows ease;
  margin: 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    position: relative;
    height: auto;
    overflow: unset;
    margin: var(${(props) => props.theme.responsive.spacing.xs}) 0
      var(${(props) => props.theme.responsive.spacing.l}) 0; // ${PAGE_MARGIN_XS}px ${PAGE_MARGIN_XL}px ${PAGE_MARGIN_S}px ${PAGE_MARGIN_XL}px;
  }
`;

export const StyledNavBar = styled.nav<{ $visible?: boolean; $isSecondaryLevel?: boolean }>`
  display: ${(props) => (props.$isSecondaryLevel ? 'none' : 'flex')};
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 0.3s ease;

  border-bottom: ${(props) => props.$isSecondaryLevel && '0'};
  background-color: var(${(props) => props.theme.colors.ui.highlight.tertiary});

  padding-left: ${PAGE_MARGIN_S}px;
  padding-right: ${PAGE_MARGIN_S}px;

  padding: ${(props) =>
    props.$isSecondaryLevel ? `0px ${PAGE_MARGIN_S}px 0px ${PAGE_MARGIN_S}px;` : '0'};

  // hides un-wanted priority nav drop down border arrow
  .background-priority-layer {
    display: none;
    position: absolute;
    top: -1px;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: var(${(props) => props.theme.colors.ui.inverted});
    z-index: 1;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: ${(props) =>
      props.$isSecondaryLevel ? `0px ${PAGE_MARGIN_M}px 0px ${PAGE_MARGIN_M}px;` : '0'};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: 100%;
    max-width: 1920px;
    position: relative;
    display: block;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    position: unset;
    top: unset;
    left: unset;
    width: auto;
    background-color: unset;
    padding-left: 0;
    padding-right: 0;

    transition-timing-function: ${(props) =>
      props.$isSecondaryLevel && props.$visible ? props.theme.easing : 'none'};
    transition-duration: 0.5s;
    transition-property: transform, opacity, height, visibility;
    opacity: ${(props) =>
      !props.$isSecondaryLevel ? '1' : props.$isSecondaryLevel && props.$visible ? '1' : '0'};
    visibility: ${(props) =>
      !props.$isSecondaryLevel
        ? 'visible'
        : props.$isSecondaryLevel && props.$visible
          ? 'visible'
          : 'hidden'};
    height: ${(props) =>
      !props.$isSecondaryLevel ? '62px' : props.$isSecondaryLevel && props.$visible ? '45px' : '0'};
    border-bottom: ${(props) => props.$visible && '1px solid'}
      var(${(props) => props.theme.colors.ui.divider});

    border-bottom: ${(props) => props.$isSecondaryLevel && 'none'};

    .background-priority-layer {
      display: block;
    }
  }
`;

export const StyledNavList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin: 0px;
  padding: 0px;
  list-style: outside none none;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    flex-wrap: wrap;
    width: 100%;
    background-color: unset;
  }
`;

export const StyledListItem = styled.li<{
  $hasParentElement?: boolean;
  $isSecondaryLevel?: boolean;
  $isMobile?: boolean;
  $priorityNavLoaded?: boolean;
  $priorityItemslength?: number;
  $hidePriorityDropDown?: boolean;
}>`
  margin-left: ${(props) =>
    props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_XS}px` : '0'};
  margin-right: ${(props) =>
    props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_XS}px` : '0'};
  padding: 0 var(${(props) => props.$isSecondaryLevel && props.theme.responsive.spacing.l});

  display: ${(props) => (props.$isMobile && !props.$hasParentElement ? 'none' : 'block')};

  &.parent-overview {
    margin-left: ${PAGE_MARGIN_XS}px;
    margin-right: ${PAGE_MARGIN_XS}px;
    padding: 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
      margin-left: ${PAGE_MARGIN_S}px;
      margin-right: ${PAGE_MARGIN_S}px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
      margin-left: ${PAGE_MARGIN_M}px;
      margin-right: ${PAGE_MARGIN_M}px;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      margin: 0;
      padding-right: var(
        ${(props) =>
          props.$isSecondaryLevel
            ? props.theme.responsive.spacing.l
            : props.theme.responsive.spacing.xl}
      );
    }
  }

  &.sub-level-parent {
    display: block;
    // sub level
    margin: 0;
    padding-left: var(
      ${(props) =>
        props.$hasParentElement
          ? props.theme.responsive.spacing.l
          : props.theme.responsive.spacing.xs}
    );
    padding-right: var(
      ${(props) =>
        props.$hasParentElement
          ? props.theme.responsive.spacing.l
          : props.theme.responsive.spacing.xs}
    );

    &:first-child {
      a {
        padding-top: var(${(props) => props.theme.responsive.spacing.xl});
        padding-left: 0;
      }
    }
  }

  &:last-child label {
    border-bottom: none;
  }

  &#moreMenuList {
    display: none;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
    &.parent-item {
      // top level
      margin-left: ${(props) =>
        props.$hasParentElement && !props.$isSecondaryLevel
          ? `${PAGE_MARGIN_S}px`
          : `${PAGE_MARGIN_XS}px`};
      margin-right: ${(props) =>
        props.$hasParentElement && !props.$isSecondaryLevel
          ? `${PAGE_MARGIN_S}px`
          : `${PAGE_MARGIN_XS}px`};

      // sub level
      margin-left: ${(props) =>
        props.$hasParentElement && props.$isSecondaryLevel
          ? `${PAGE_MARGIN_XS}px`
          : `${PAGE_MARGIN_S}px`};
      margin-right: ${(props) =>
        props.$hasParentElement && props.$isSecondaryLevel
          ? `${PAGE_MARGIN_XS}px`
          : `${PAGE_MARGIN_S}px`};
    }
    margin: 0;

    margin-left: ${(props) =>
      props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_S}px` : '0'};
    margin-right: ${(props) =>
      props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_S}px` : '0'};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    &.parent-item {
      margin-left: ${PAGE_MARGIN_M}px;
      margin-right: ${PAGE_MARGIN_M}px;
    }
    margin-left: ${(props) =>
      props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_M}px` : '0'};
    margin-right: ${(props) =>
      props.$hasParentElement && !props.$isSecondaryLevel ? `${PAGE_MARGIN_M}px` : '0'};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    &:nth-child(1n + 2) {
      padding-block-start: 0;
      padding-inline-end: var(${(props) => props.theme.responsive.spacing.l});
    }
    // Add padding to the first element if no parent element is set
    padding-inline-end: var(
      ${(props) => (!props.$hasParentElement ? props.theme.responsive.spacing.l : 0)}
    );

    &.parent-item {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      &:first-child {
        a {
          padding-top: 0;
        }
      }
      padding-right: var(
        ${(props) =>
          props.$isSecondaryLevel
            ? props.theme.responsive.spacing.l
            : props.theme.responsive.spacing.xl}
      );
    }

    &#moreMenuList {
      padding: 0;
      position: ${(props) => (!props.$isSecondaryLevel ? 'absolute' : 'unset')};
      right: ${(props) => (!props.$isSecondaryLevel ? '-10px' : 'unset')};
      width: ${(props) => (!props.$isSecondaryLevel ? '52px' : 'unset')};
      top: 0;
      ${(props) =>
        props.$priorityItemslength && props.$priorityItemslength > 0 && `display : block;`};

      ${(props) =>
        props.$priorityNavLoaded &&
        props.$priorityItemslength &&
        props.$priorityItemslength > 0 &&
        !props.$isMobile &&
        `border-bottom: 2px solid var(${props.theme.colors.ui.primary});`};
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    &#moreMenuList {
      ${(props) => props.$hidePriorityDropDown && `border-bottom: none;`};
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    &#moreMenuList {
      right: ${(props) => (!props.$isSecondaryLevel ? '0' : 'unset')};
    }
  }
`;

export const StyledMobileListItem = styled(StyledListItem)<{ $hasParentElement?: boolean }>`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export const StyledLink = styled.a<{
  $hasParentElement?: boolean;
  $isSecondaryLevel?: boolean;
  $isLastElement?: boolean;
}>`
  display: flex;
  text-decoration: none;
  font-family: var(${(props) => props.theme.fontFamily});
  font-size: var(${(props) => props.theme.responsive.typography.copy1.fontSize});
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.003px;
  padding-left: var(${(props) => props.$hasParentElement && props.theme.responsive.spacing.l});
  padding-right: var(${(props) => props.$hasParentElement && props.theme.responsive.spacing.l});
  padding-top: var(${(props) => props.theme.responsive.spacing.m});
  padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
  ${(props) =>
    props.$hasParentElement
      ? `margin-left: 0; margin-right: 0;`
      : `margin-left: ${PAGE_MARGIN_XS}px; margin-right: ${PAGE_MARGIN_XS}px;`};

  color: var(${(props) => props.theme.colors.interaction.secondary.default});
  border-bottom: 1px solid var(${(props) => props.theme.colors.ui.highlight.tertiary});

  &.secondary-level-link {
    padding-bottom: var(${(props) => props.theme.responsive.spacing.xs});
    margin-left: 0;
    margin-right: 0;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      ${(props) => props.$isSecondaryLevel && 'border-bottom: 1px solid transparent;'};
    }
  }

  ${(props) =>
    props.$isLastElement ? 'border-bottom: 0;' : ``}; // remove border from last element

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `margin-left: ${PAGE_MARGIN_S}px; margin-right: ${PAGE_MARGIN_S}px;`
        : `inherit`};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `margin-left: ${PAGE_MARGIN_M}px; margin-right: ${PAGE_MARGIN_M}px;`
        : `inherit`};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: auto; // ${(props) => (props.$isSecondaryLevel ? '45px' : 'auto')};
    line-height: ${(props) => (props.$isSecondaryLevel ? `unset` : '60px')};
    font-size: var(
      ${(props) =>
        props.$isSecondaryLevel
          ? props.theme.responsive.typography.copy2.fontSize
          : props.theme.responsive.typography.copy1.fontSize}
    );
    &.secondary-level-link {
      margin-left: 0;
      margin-right: 0;
    }

    &:hover {
      color: var(${(props) => props.theme.colors.ui.primary});
    }

    &:active {
      color: var(${(props) => props.theme.colors.ui.primary});
    }
  }

  &.parent-link {
    margin-left: 0;
    font-weight: 700;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      font-weight: normal;
      font-size: var(
        ${(props) =>
          props.$isSecondaryLevel
            ? props.theme.responsive.typography.copy2.fontSize
            : props.theme.responsive.typography.order3.fontSize}
      );
    }
  }

  &.semi-active-link {
    color: var(${(props) => props.theme.colors.ui.primary});
  }

  &.active-link {
    color: var(${(props) => props.theme.colors.ui.primary});
    font-weight: 500;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      border-bottom: 2px solid var(${(props) => props.theme.colors.ui.primary});
      font-weight: normal;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0;
    margin: 0;
    border-bottom: none;
  }
`;

// Mobile accordion dropdown label
export const StyledTextLabel = styled(StyledLink)`
  width: 100%;
  border-bottom: 0;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

// Parent label for Samelevel and Sublevel lists
export const StyledOverViewLink = styled.a<{
  $hasParentElement?: boolean;
  $isSecondaryLevel?: boolean;
  $isActive?: boolean;
  $subPageParent?: boolean;
}>`
  display: flex;
  text-decoration: none;
  font-family: var(${(props) => props.theme.fontFamily});
  font-size: var(
    ${(props) =>
      props.$isSecondaryLevel
        ? props.theme.responsive.typography.copy2.fontSize
        : props.theme.responsive.typography.copy1.fontSize}
  );
  ${(props) => (props.$isActive ? 'font-weight: 500;' : `font-weight: normal;`)};

  letter-spacing: 0.003px;

  cursor: pointer;

  padding-left: var(${(props) => props.theme.responsive.spacing.l});
  padding-bottom: var(
    ${(props) =>
      props.$isSecondaryLevel
        ? props.theme.responsive.spacing.xs
        : props.theme.responsive.spacing.m}
  );

  color: var(
    ${(props) =>
      props.$isActive
        ? props.theme.colors.ui.primary
        : props.theme.colors.interaction.secondary.default}
  );

  ${(props) =>
    props.$isSecondaryLevel && props.$subPageParent
      ? `border-bottom: 2px solid ;${props.theme.colors.ui.primary}`
      : 'margin-top: -1px;'};
  ${(props) => props.$hasParentElement && 'margin-top: 0;'};
  height: auto;

  padding-left: 0;

  &.active-link {
    color: var(${(props) => props.theme.colors.ui.primary});
    font-weight: 700;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    font-weight: normal;
    ${(props) => (!props.$isSecondaryLevel ? 'line-height: 58px' : ``)};
    font-size: var(
      ${(props) =>
        props.$isSecondaryLevel
          ? props.theme.responsive.typography.copy2.fontSize
          : props.theme.responsive.typography.order3.fontSize}
    );

    ${(props) => !props.$isSecondaryLevel && 'padding-bottom: 2px;'};

    // hidden border used to help verticle alignment with parent and nav items

    &.active-link {
      color: var(${(props) => props.theme.colors.ui.primary});
      @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
        border-bottom: 2px solid var(${(props) => props.theme.colors.ui.primary});
        font-weight: normal;
      }
    }
  }

  &:hover {
    color: var(${(props) => props.theme.colors.ui.primary});
  }

  &:active {
    color: var(${(props) => props.theme.colors.ui.primary});
  }
`;

export const StyledOverViewLabel = styled(StyledOverViewLink).attrs({ as: 'label' })`
  pointer-events: none;
`;

export const StyledMoreDropDown = styled.div<{
  $activePriortyDropDown?: boolean;
  $priorityNavLoaded?: boolean;
}>`
  display: none;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: flex;
    width: 100%;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    margin-right: -10px;
    align-items: flex-end;
    cursor: pointer;

    [name='arrow-up'],
    svg {
      rotate: 180deg;
      transition: all 0.3s ease;
      border-bottom: 2px solid transparent;
      ${(props) => (props.$activePriortyDropDown ? `rotate: 0deg;` : ``)}
    }
  }
`;

export const StyledOverarchingName = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(${(props) => props.theme.colors.interaction.label.default});
  padding-top: var(${(props) => props.theme.responsive.spacing.m});
  padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
  padding-left: ${PAGE_MARGIN_XS}px;
  padding-right: ${PAGE_MARGIN_XS}px;

  border-bottom: 1px solid var(${(props) => props.theme.colors.ui.highlight.tertiary});
  cursor: pointer;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
    padding-left: ${PAGE_MARGIN_S}px;
    padding-right: ${PAGE_MARGIN_S}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-left: ${PAGE_MARGIN_M}px;
    padding-right: ${PAGE_MARGIN_M}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export const StyledArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  rotate: -180deg;
  transition: all 0.3s ease;
`;

export const StyledOverarchingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: -1;

  p {
    font-size: var(${(props) => props.theme.responsive.typography.order4.fontSize});
  }

  span {
    font-size: var(${(props) => props.theme.responsive.typography.copy2.fontSize});
  }
`;

export const StyledSecondaryOverarchingName = styled.label<{ $hasParentElement?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(${(props) => props.theme.colors.interaction.label.default});
  padding-left: var(${(props) => props.$hasParentElement && props.theme.responsive.spacing.l});
  padding-right: var(${(props) => props.$hasParentElement && props.theme.responsive.spacing.l});
  border-bottom: 1px solid var(${(props) => props.theme.colors.ui.highlight.tertiary});
  ${StyledLink} {
    margin-left: 0;
    margin-right: 0;
  }
  ${(props) =>
    props.$hasParentElement
      ? `margin-left: 0; margin-right: 0;`
      : `margin-left: ${PAGE_MARGIN_XS}px; margin-right: ${PAGE_MARGIN_XS}px;`};

  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `margin-left: ${PAGE_MARGIN_S}px; margin-right: ${PAGE_MARGIN_S}px;`
        : `inherit`};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `margin-left: ${PAGE_MARGIN_M}px; margin-right: ${PAGE_MARGIN_M}px;`
        : `inherit`};
  }

  cursor: pointer;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  &:checked ~ ${StyledSubMenu} {
    grid-template-rows: 1fr;
  }

  &:checked ~ ${StyledSubMenu} > ${StyledNavBar} {
    padding-top: var(${(props) => props.theme.responsive.spacing.m});
    padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
    &.nav-bar-top {
      height: 100vh;
      overflow-y: scroll;
      @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
        height: 62px;
        overflow-y: hidden;
      }
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &:checked ~ ${StyledSubMenu} > ${StyledNavBar} > ${StyledNavList} {
    &.top-ul-list {
      margin-bottom: 100px;
      @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
        margin-bottom: unset;
      }
    }
  }

  &:checked ~ ${StyledOverarchingName} > ${StyledArrowIcon} {
    rotate: 0deg;
  }

  &:checked ~ div > span > ${StyledSecondaryOverarchingName} > ${StyledArrowIcon} {
    rotate: 0deg;
  }
`;

export const StyledSecondLevelSubMenu = styled(StyledSubMenu)<{ $hasParentElement?: boolean }>`
  padding: 0 var(${(props) => !props.$hasParentElement && props.theme.responsive.spacing.m});
  ${(props) => !props.$hasParentElement && `margin-left: 0; margin-right: 0;`};
  ${StyledListItem} {
    ${(props) => !props.$hasParentElement && `padding: 0;`};
    &.sub-level-parent {
      ${(props) => !props.$hasParentElement && `margin-left: 0; margin-right: 0;`};
      ${StyledLink} {
        ${(props) =>
          !props.$hasParentElement && `padding-left: var(${props.theme.responsive.spacing.xs});`};
      }
    }
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.s}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `padding-left: ${PAGE_MARGIN_S}px; padding-right: ${PAGE_MARGIN_S}px;`
        : `padding: 0;`};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) {
    ${(props) =>
      !props.$hasParentElement
        ? `padding-left: ${PAGE_MARGIN_M}px; padding-right: ${PAGE_MARGIN_M}px;`
        : `padding: 0;`};
  }
  ${StyledLink} {
    padding-top: var(${(props) => props.theme.responsive.spacing.m});
    padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
    &.parent-link {
      font-weight: normal;
    }
    &.secondary-level-link {
      ${(props) =>
        !props.$hasParentElement &&
        `padding-left: ${PAGE_MARGIN_S}px; padding-right: ${PAGE_MARGIN_S}px;`}
    }
    @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
      display: none;
    }
  }
`;
