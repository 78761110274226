/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { StyledListItem, StyledOverViewLabel, StyledOverViewLink } from './NavigationStyles';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { getParentItemHref, getParentItemTitle } from '../../utils/utils';
import { ActiveLinks } from '../../../types';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';

interface OverViewLabelProps {
  isSecondaryLevel?: boolean;
}

interface LabelProps extends OverViewLabelProps {
  isParentItem?: boolean;
}

const Link = React.memo(() => {
  const dispatch = useInPageDispatch();
  const { parentPageInpageNavigation, isLoadedFromUrl } = useInPageNavContext();
  const propertyPath = 'parentPageInpageNavigation';
  if (!getParentItemHref(parentPageInpageNavigation)) {
    return <SecondLevelLabel isParentItem />;
  }

  const handleSelectedItem = ({ id, index, text, href, target }: ActiveLinks) => {
    // eslint-disable-next-line no-unused-expressions
    dispatch &&
      dispatch({
        type: INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK,
        payload: {
          sameLevelActiveLink: { id, index, text, href, target },
        },
      });
  };

  return (
    <StyledOverViewLink
      $hasParentElement
      $isSecondaryLevel={false}
      href={getParentItemHref(parentPageInpageNavigation)}
      className={!isLoadedFromUrl ? 'active-link' : ''}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectedItem({
          id: parentPageInpageNavigation?.id || '',
          index: 0,
          text: parentPageInpageNavigation?.fields?.subLevelParentLabel || '',
          href: parentPageInpageNavigation?.fields?.internalLink?.path || '',
          target: '_self',
        });
        if (typeof window !== 'undefined') {
          const path = parentPageInpageNavigation?.fields?.internalLink?.path || '';
          window.location.href = path;
        }
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeElement type="text" property="subLevelParentLabel" label="Parent Page Item Label">
          {getParentItemTitle(parentPageInpageNavigation)}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  );
});

const SecondLevelLabel = React.memo(({ isParentItem, isSecondaryLevel }: LabelProps) => {
  const dispatch = useInPageDispatch();
  const {
    parentPageInpageNavigation,
    sameLevelActiveLink,
    topLevelActiveLink,
    secondLevelActiveLink,
    isLoadedFromUrl,
  } = useInPageNavContext();
  const [activeLink, setActivelink] = useState<ActiveLinks>();
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (!isParentItem) {
      setActivelink(isSecondaryLevel ? topLevelActiveLink : secondLevelActiveLink);
    }
  }, [isParentItem]);

  useEffect(() => {
    const overviewLabel =
      activeLink?.subLevelChildLabel || activeLink?.text || topLevelActiveLink?.text || '';

    setLabel(
      activeLink?.parentLabel ||
        overviewLabel ||
        parentPageInpageNavigation?.fields?.subLevelParentLabel ||
        '',
    );
  }, [topLevelActiveLink, activeLink, parentPageInpageNavigation]);

  const handleSelectedItem = ({ id, index, text, href, target }: ActiveLinks) => {
    // eslint-disable-next-line no-unused-expressions
    dispatch &&
      dispatch({
        type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
        payload: {
          secondLevelActiveLink: { id, index, text, href, target },
        },
      });
  };

  const propertyPath = isSecondaryLevel
    ? `sameLevelInpageNavigation.${activeLink?.index}`
    : 'parentPageInpageNavigation';

  return sameLevelActiveLink?.subLevelChildLabel &&
    sameLevelActiveLink?.href &&
    !topLevelActiveLink &&
    !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      $isActive
      $subPageParent
      href={sameLevelActiveLink?.href}
      target={sameLevelActiveLink?.target}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectedItem({
          id: sameLevelActiveLink?.id,
          index: sameLevelActiveLink?.index,
          text: sameLevelActiveLink?.text,
          href: sameLevelActiveLink?.href,
          target: sameLevelActiveLink?.target,
        });
        if (typeof window !== 'undefined' && sameLevelActiveLink?.href) {
          const { href, target } = sameLevelActiveLink;
          target === '_blank' ? window.open(href) : (window.location.href = href);
        }
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="Text" label="Parent Page Item Link">
          {sameLevelActiveLink.subLevelChildLabel}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : topLevelActiveLink?.href && !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      href={topLevelActiveLink?.href}
      target={topLevelActiveLink?.target}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectedItem({
          id: topLevelActiveLink?.id,
          index: topLevelActiveLink?.index,
          text: topLevelActiveLink?.text,
          href: topLevelActiveLink?.href,
          target: topLevelActiveLink?.target,
        });
        if (typeof window !== 'undefined' && topLevelActiveLink?.href) {
          const { target, href } = topLevelActiveLink;
          target === '_blank' ? window.open(href) : (window.location.href = href);
        }
      }}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="subLevelChildLabel" label="Sub Level Child Label">
          {topLevelActiveLink.subLevelChildLabel || topLevelActiveLink.text}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : (
    <StyledOverViewLabel
      $hasParentElement
      $isSecondaryLevel={isSecondaryLevel}
      className={!isLoadedFromUrl && isParentItem ? 'active-link' : ''}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeReference propertyPath={propertyPath} label="Label field">
          <UeElement type="text" property="Text" label="Parent Page Item Label">
            {label}
          </UeElement>
        </UeReference>
      </UeContainer>
    </StyledOverViewLabel>
  );
});

const OverViewLabel: React.FC<OverViewLabelProps> = ({ isSecondaryLevel }) => {
  return (
    <StyledListItem
      role="none"
      className="parent-item"
      $isSecondaryLevel={isSecondaryLevel}
      $hasParentElement
      $isMobile
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {isSecondaryLevel ? <SecondLevelLabel isSecondaryLevel={isSecondaryLevel} /> : <Link />}
    </StyledListItem>
  );
};

export default OverViewLabel;
