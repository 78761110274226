/**
 * Converts a relative URL to an absolute URL
 * @param relativeURL - The relative URL to convert
 * @param countryDomainURL - The absolute country domain URL set in the config
 * @returns The absolute URL or empty string if invalid
 */
export function relativeToAbsoluteURL(
  relativeURL: string | null | undefined,
  countryDomainURL: string = typeof window !== 'undefined' ? window.location.href : '',
): string {
  try {
    if (!relativeURL) {
      return '';
    }

    if (!countryDomainURL || countryDomainURL === '') {
      return relativeURL;
    }
    const trimmedURL: string = relativeURL.trim();

    // Handle data URLs
    if (trimmedURL.startsWith('data:')) {
      return trimmedURL;
    }

    // Handle anchor links
    if (trimmedURL.startsWith('#')) {
      const base: string = countryDomainURL.split('#')[0];
      return `${base}${trimmedURL}`;
    }

    // Regular expression for absolute URLs
    const absolutePattern: RegExp = /^(?:[a-z+]+:)?\/\/|^www\./i;

    if (absolutePattern.test(trimmedURL)) {
      // Add https:// to www. URLs
      if (trimmedURL.startsWith('www.')) {
        return `https://${trimmedURL}`;
      }
      return trimmedURL;
    }

    // Handle protocol-relative URLs
    if (trimmedURL.startsWith('//')) {
      const baseUrlProtocol: string = new URL(countryDomainURL).protocol;
      return `${baseUrlProtocol}${trimmedURL}`;
    }

    const absolute: URL = new URL(
      trimmedURL,
      countryDomainURL === '' ? window.location.href : countryDomainURL,
    );

    return absolute.href;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid Country Domain URL:', error);
    return '';
  }
}
