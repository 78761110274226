import React from 'react';
import { Avatar } from '@audi/audi-ui-react';
import styled, { AnyStyledComponent } from 'styled-components';
import { AudiUserMenuAvatarProps } from '../../interfaces/header-components.interfaces';

const StyledAvatar = styled(Avatar as AnyStyledComponent)`
  & img {
    position: inherit;
  }
`;

const hasOnlyLatinCharacters = (initials: string): boolean => {
  //  returns true for all characters that are latin
  const regex = /^\p{Script=Latin}+$/u;
  return regex.test(initials);
};

const AudiUserMenuAvatar: React.FC<AudiUserMenuAvatarProps> = ({ src, initials }) => {
  if (src) {
    return <StyledAvatar size="small" label="" src={src} />;
  }
  if (initials && hasOnlyLatinCharacters(initials)) {
    return <StyledAvatar size="small" label={initials} />;
  }

  return <Avatar icon label="" size="small" data-testid="default-icon" />;
};

export default AudiUserMenuAvatar;
