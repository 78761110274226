/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { isAbsoluteURL } from '../../utils/utils';

export enum EventAction {
  INTERNAL = 'internal_link',
  EXIT = 'exit_link',
  COMPONENT_NAME = 'inpage navigation',
  IMPRESSION = `${COMPONENT_NAME} - impression`,
  SAME_LEVEL_NAVIGATION = 'same-level-navigation',
  SUB_LEVEL_NAVIGATION = 'sub-level-navigation',
  NAVIGATION = 'navigation',
  NONE_INTERACTION = 'non_interaction',
}

export interface Tracking {
  appReady(): void;
  impression(clickTrackingAttributes: ClickTrackingAttributes): void;
  toggleNavigation(clickTrackingAttributes: ClickTrackingAttributes): void;
  navigationItemClick(clickTrackingAttributes: ClickTrackingAttributes): void;
}

export const getIndexPosition = (index: number): number => {
  const indexPosition = index + 1;
  return indexPosition;
};

interface ClickTrackingAttributes {
  elementName: string;
  label: string;
  clickID: string;
  targetURL: string;
  pos: string;
  value?: string;
  isSubNavigation?: boolean;
  eventName?: string;
}

export function useTracking(
  trackingService: TrackingServiceV2 | undefined,
  attributes?: object,
): Tracking {
  const defaultComponentAdditions = {
    componentInfo: {
      implementer: 2,
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    appReady: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: EventAction.COMPONENT_NAME,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${EventAction.COMPONENT_NAME} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(readyEvent);
      }
    },

    impression: (clickTrackingAttributes) => {
      if (trackingService) {
        const impressionEvent = {
          event: {
            attributes: {
              componentName: 'inpage-navigation',
              elementName: EventAction.IMPRESSION,
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: clickTrackingAttributes.value || '', // value of the parent page
            },
            eventInfo: {
              eventAction: 'impression',
              eventName: EventAction.IMPRESSION,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(impressionEvent);
      }
    },

    toggleNavigation: (clickTrackingAttributes) => {
      if (trackingService) {
        const toggleEvent = {
          event: {
            attributes: {
              componentName: EventAction.COMPONENT_NAME,
              elementName: clickTrackingAttributes.elementName,
              label: clickTrackingAttributes.label,
              clickID: clickTrackingAttributes.clickID,
              pos: clickTrackingAttributes.pos,
              targetURL: clickTrackingAttributes.targetURL,
              value: clickTrackingAttributes.value,
            },
            eventInfo: {
              eventAction: 'navigation',
              eventName: clickTrackingAttributes.eventName,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(toggleEvent);
      }
    },

    navigationItemClick: (clickTrackingAttributes) => {
      if (trackingService) {
        const clickEvent = {
          event: {
            attributes: {
              componentName: EventAction.COMPONENT_NAME,
              elementName: clickTrackingAttributes.elementName,
              label: clickTrackingAttributes.label,
              clickID: clickTrackingAttributes.clickID,
              pos: clickTrackingAttributes.pos,
              targetURL: clickTrackingAttributes.targetURL,
              value: clickTrackingAttributes.value,
            },
            eventInfo: {
              eventAction: isAbsoluteURL(clickTrackingAttributes.targetURL)
                ? 'exit_link'
                : 'internal_link',
              eventName: clickTrackingAttributes.eventName,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(clickEvent);
      }
    },
  };
}
